import React from 'react'
import Brand from '../../../Assets/Images/Branding.jpg'
import Styles from '../../../Assets/Styles/Style'
import Aos from 'aos'
import "aos/dist/aos.css"
import Logo from "../../../Assets/Images/Logo/FS Logo.png"


const Branding=()=> {
  return (
    <div className='container-fluid m-0 p-0'>
     
           <div className='row m-0 p-0' style={{backgroundImage:`url(${Brand})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'100vh'}}>
       <div className='col-12 m-0 p-0  position-relative'>
        {/* For web view Start */}
         <div class="shadow py-5 px-4 bg-light d-none d-md-block animate__animated animate__zoomIn animate__delay-2s" style={{ backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,position:'absolute',left:'10%',bottom:'20%',width:'30%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius}}>
    <img className='img-fluid' style={{marginLeft:"25%", width:'150px',height:'150px'}} src={Logo} alt=''></img>
           
            {/* <h1 className='fw-bold mt-2 mb-3' 
                style={{fontSize:Styles.brandHeading ,marginLeft:"18%",}}>
             FS Group
            </h1> */}
            <p className='mb-3 mt-4' style={{fontSize:Styles.brandDiscripition,textAlign:'justify'}}>
            Executing Vision with Excellence  
            </p>
            {/* 10-may-2023 */}
            {/* <p style={{fontsize:Styles.brandText}}>Some text place here</p>
          <button className='w-50 p-3 border-0 m-0 mb-2' 
                  style={{
                  backgroundColor:Styles.brandReadMoreBtnColor,
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.brandReadMoreBtnTextSize
                  }}>Read More</button> */}
         </div>
        {/* For web view End */}
        {/* For Mobile view Start*/}
        <div class="shadow py-5 px-4 d-block  d-md-none position-absolute  top-50 start-50 translate-middle" style={{ backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'85%',borderRadius:'15px'}}>
    <img className='img-fluid' style={{ width:'75px',height:'75px'}} src={Logo} alt=''></img>
            
            <h1 className='fw-bold mt-2 mb-3' style={{fontSize:Styles.mobilebrandHeading}}>
            FS Group
            </h1>
            <p className='mb-3 ' style={{fontSize:Styles.brandDiscripition,textAlign:'justify'}}>
            Executing Visions with Excellence.

            </p>
            {/* <p style={{fontsize:Styles.brandText}}>Some text place here</p>
          <button className='w-50 p-3 border-0 m-0 mb-2' 
                  style={{
                  backgroundColor:Styles.brandReadMoreBtnColor,
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.mobilebuttonbrandHeading,
                  }}>Read More</button> */}
         </div>
        {/* For mobile view End */}
       </div>
      </div>

    </div>
  )
}

export default Branding