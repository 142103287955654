import React from 'react'
import Styles from '../../../Assets/Styles/Style'
const Stratagies=()=> {
  return (
    <div className='d-flex flex-column ' style={{backgroundColor:Styles.siteStrategiesBackgroundColor}}>
    <div className='d-flex flex-column flex-md-row py-2 py-md-5 '>
      <div data-aos={"fade-up"} className=' w-50 d-flex align-item-center justify-content-center stratigy2 '>
        <h1 style={{fontSize:Styles.strategyHeading,marginLeft:'-5%',marginTop:"10%"}} className='fw-bolder' >About Us</h1>
      </div>
      <div className=' stratigy2'style={{marginRight:"10%",width:"40%"}}>
        <p className='stratigy2text ' style={{fontSize:Styles.brandText  }}>
          Foundation of FS Group was laid by Dr. Najam Farooq & Dr. Ishtiaq Ahmed back in 2007 with the establishment of Future Scientific (A medical diagnostic company). Based on their academic & practical knowledge of pathology Laboratory they worked on niche areas of pathology by introducing modern technologies & novel marker testing. With times they established multiple pathology Laboratories, Health care centers & Health care IT company and emerged as a Group of companies named as FS Group. FS Group is very much focused in Pakistani health care industry & currently employing a team of about 300.
        </p>
      </div>
    </div>
    {/* <div className='d-flex flex-column flex-md-row align-items-center juatify-content-center w-100' style={{padding:'0% 10% 0 10%'}}>
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>01</h1>
           </div>
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className=' text-center px-1'>
             Article evident arrived express highet men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes worth too. Comfort produce husband boy her had hearing. Law others theirs passed but wishes. You day real less till dear read
           </p>
           </div>
      </div>
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>02</h1>
           </div>
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className=' text-center px-1'>
             Article evident arrived express highet men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes worth too. Comfort produce husband boy her had hearing. Law others theirs passed but wishes. You day real less till dear read
           </p>
           </div>
      </div>
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>03</h1>
           </div>
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className=' text-center px-1'>
             Article evident arrived express highet men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes worth too. Comfort produce husband boy her had hearing. Law others theirs passed but wishes. You day real less till dear read
           </p>
           </div>
      </div> 
      
    </div>
    <div className='d-flex flex-column flex-md-row align-items-center juatify-content-center w-100' style={{padding:'0% 10% 0 10%',marginTop:"5%"}}>
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>04</h1>
           </div>
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className=' text-center px-1'>
             Article evident arrived express highet men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes worth too. Comfort produce husband boy her had hearing. Law others theirs passed but wishes. You day real less till dear read
           </p>
           </div>
      </div>
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>05</h1>
           </div>
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className=' text-center px-1'>
             Article evident arrived express highet men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes worth too. Comfort produce husband boy her had hearing. Law others theirs passed but wishes. You day real less till dear read
           </p>
           </div>
      </div>
      <div data-aos="fade-up" className='mx-3 p-3 d-flex flex-column justify-content-center align-items-center stratigy1' style={{width:'33%',borderRadius:'20px',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
           <div data-aos="zoom-in" className='d-flex justify-content-center align-items-center p-4 mb-4' style={{width:'35%',marginTop:'-10%', borderRadius:Styles.ReadMoreBtnTextColor.themeSmallBoxeBorderRadius,backgroundColor:Styles.brandReadMoreBtnColor}}>
            <h1 style={{color:Styles.ReadMoreBtnTextColor.themeColorLight}}>06</h1>
           </div>
           <div className='d-flex w-100 mt-3 pb-0 px-3'>
           <p className=' text-center px-1'>
             Article evident arrived express highet men did boy. Mistress sensible entirely am so. Quick can manor smart money hopes worth too. Comfort produce husband boy her had hearing. Law others theirs passed but wishes. You day real less till dear read
           </p>
           </div>
      </div> 
      
    </div> */}

















    </div>
  )
}

export default Stratagies