import React from 'react'
import Styles from '../../../Assets/Styles/Style'
import Team from '../../../Assets/Images/Team.jpg'
import TeamLow from '../../../Assets/Images/TeamLowePart.jpg'
import b1 from '../../../Assets/Images/Logo/Future LogoFull.png'
import b2 from '../../../Assets/Images/Logo/FS Lab LogoFull.png'
import b3 from '../../../Assets/Images/Logo/MPL Logofull.png'
import b4 from '../../../Assets/Images/Logo/GP LogoFull.png'
import b5 from '../../../Assets/Images/Logo/FS Soft LogoFull.png'
import b6 from '../../../Assets/Images/Ecommerce.png'
const About=()=> {
  return (
    <div className='container-fluid m-0 p-0 py-5' style={{background:"#e5e5e5"}}>
    <div className='row m-0 p-0'>
    {/* <div className='col-12 m-0 p-0' style={{backgroundImage:`url(${Team})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'85vh'}}>    */}
      
      <div className='col-12 m-0 p-0  d-flex justify-content-center'  
      // style={{backgroundImage:`url(${Team})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'85vh'}}
      >   
        <h1 style={{fontSize:Styles.strategyHeading,}} className='fw-bolder' >What We Do</h1>
      
      </div>
      {/* <div className='col-12 d-flex justify-content-center mainAboutBox' style={{backgroundImage:`url(${TeamLow})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'60vh'}}> */}
      <div className='col-12 d-flex justify-content-center mainAboutBox'
      //  style={{backgroundImage:`url(${TeamLow})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'60vh'}}
       >
         {/*boxes div  */}
         <div className='w-75 d-flex flex-row' style={{marginTop:'2%'}}>
         <div className='col-12'>
             <div className='row m-0 p-0 d-flex flex-row gap-3 ' >
             {/* 1 */}
             {/* <div className='m-0 px-1 py-4 shadow businessBox' style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0' style={{cursor:"pointer"}} onClick={()=>{
                      window.open('https://mpl-labs.pk')
                      

              }}>
                <div className='col-12 col-md-4 m-0 p-0' >
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b1}`}></img>
                </div>
                </div>
                <div className='col-12 col-md-8 m-0 p-0 aboutText'>
                    <h5 className='mb-2'>Future Scientific</h5>
                    <small>Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                </div>
                <div className='col-12 m-0 p-0 '>
                  
                    
                   
                </div>
              </div>
            </div> */}
            {/* 1 */}
              <div className='m-0 px-1 py-4 shadow businessBox' style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'65.2%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0' >
                <div className='col-12 col-md-12 m-0 p-0' >
                <div className='p-3 d-flex justify-content-center'>
                <img 
                style={{width:"250px", height:"70px"}}
                className=' img-fluid m-0 pt-0' src={`${b1}`}></img>
                </div>
                </div>
                <div className='col-12 col-md-12 m-0 p-0 aboutText' 
                style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}
                >
                    {/* <h5 className='mb-2 'style={{marginLeft:window.innerWidth>500?"2%":"0%",marginRight:window.innerWidth>500?"2%":"0%"}}>Future Scientific</h5> */}
                    <p style={{marginLeft:window.innerWidth>500?"2%":"0%",marginRight:window.innerWidth>500?"2%":"0%"}} className={window.innerWidth>500?"":'mb-4'}>Future Scientific was established in 2007 and is one of the leading diagnostic solution provider of the country. Future Scientific is very well known because of their specialty testing solutions & is always one step ahead to introduce the novel & modern technologies to the pathology segment of Pakistan.  Future Scientific is proud partner of Pakistan’s leading Hospitals & Pathology Laboratories including Agha Khan University Hospital, Shaukat Khanum Memorial Hospital, Shifa International Hospital, Rehman Medical Institute, Chughtai Labs, Islamabad Diagnostic Centre, Excel labs , Metropole Laboratories and many more...</p>
                    <button className='w-20 p-3 border-0 m-0 mb-2'
                     onClick={()=>{
                      window.open('http://futurescientific.com.pk/')
              }} 
                  style={{
                  backgroundColor:"#f4b800",
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.brandReadMoreBtnTextSize,
                  alignSelf:"center",
                  cursor:"pointer"
                  }}>Read More</button> 
                </div>
                <div className='col-12 m-0 p-0 '>
                    {/* <a href='https://www.mpl-labs.pk/'>Click here</a> */}
                    
                   
                </div>
              </div>
            </div>
          
           
             {/* 2 */}
             <div className= {window.innerWidth>500?'m-0 px-1 py-4  shadow businessBox':'m-0 px-1 py-4 mt-5 shadow businessBox'} style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh',marginTop:"50vh"}}>
              <div className='row m-0 p-0'
              //   style={{cursor:"pointer"}} onClick={()=>{
              //         window.open('https://www.fslabs.com.pk/')
              //  }}
               >
                <div className='col-12 col-md-12 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='img-fluid m-0 pt-0' 
                style={{width:"180px", height:"70px"}}
                 src={`${b2}`}
                ></img>
                </div>
                </div>
                <div className='col-12 col-md-12 m-0 p-0 aboutText'
                style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}
                >
                    {/* <h5 className='mb-2' style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%"}}>FS Lab Services</h5> */}
                    <p style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%"}} >In 2014 FS Lab services was founded in the District of Chakwal ( Home Town area of Founders of FS Group). It was the first state of the art Pathology facility of the area and now is the leading brand in Chakwal.</p>
                    <button className='w-20 p-3 border-0 m-0 mb-2 mt-5'
                     onClick={()=>{
                      // window.open('http://futurescientific.com.pk/')
                      window.open('https://www.fslabs.com.pk/')

              }} 
                  style={{
                  backgroundColor:"#f4b800",
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.brandReadMoreBtnTextSize,
                  alignSelf:"center",
                  cursor:"pointer"
                  }}>Read More</button>
                </div>
              </div>
            </div>
            
            {/* 3 */}
            <div className= {window.innerWidth>500?'m-0 px-1 py-4  shadow businessBox':'m-0 px-1 py-4 mt-5 shadow businessBox'} style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0'>
                <div className='col-12 col-md-12 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className=' img-fluid m-0 pt-0' 
                style={{height:"150px",width:"150px"}}
                src={`${b5}`}></img>
                </div>
                </div>
                <div className='col-12 col-md-12 m-0 p-0 aboutText'
                style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}
                >
                    {/* <h5  style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%"}}className='mb-2'>FS Soft</h5> */}
                    <p style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%"}} className={window.innerWidth>500?"":'mb-4'}>FS Soft (medical IT solution company) was established in 2016 when the in-house need and market demand was souring. We have developed state of the Laboratory information & Management systems (LIMS), Hospital information & management system (HMIS), Clinics OPD modules & Pharmacy management systems.</p>
                    <button className='w-20 p-3 border-0 m-0 mb-2'
                     onClick={()=>{
                      // window.open('http://futurescientific.com.pk/')
                      window.open('https://fssoft.pk/')

              }} 
                  style={{
                  backgroundColor:"#f4b800",
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.brandReadMoreBtnTextSize,
                  alignSelf:"center",
                  cursor:"pointer"
                  }}>Read More</button>
                </div>
              </div>
            </div>
           
            {/* 4 */}
            <div className= {window.innerWidth>500?'m-0 px-1 py-4  shadow businessBox':'m-0 px-1 py-4 mt-5 shadow businessBox'} style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
            <div className='row m-0 p-0'   >
                <div className='col-12 col-md-12 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='img-fluid m-0 pt-0'
                // style={{width:"100px", height:"70px"}}
                src={`${b3}`}></img>
                </div>
                </div>
                <div className='col-12 col-md-12 m-0 p-0 aboutText'
                style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}
                >
                    {/* <h5 style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%",marginTop:window.innerWidth>500?"15%":"0%",}} className='mb-2'>MPL </h5> */}
                  {window.innerWidth>500?
                    <p style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%",marginTop:window.innerWidth>500?"15%":"0%"}} className={window.innerWidth>500?"":'mb-4'}>Metropole Laboratories private Limited was established in 2018 in the Capital City of Islamabad. It is one of the most advanced Pathology Laboratory of the country & is ISO 15189 accredited.<br/><br/><br></br> </p>
                :
                <p style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%",marginTop:window.innerWidth>500?"15%":"0%"}} className={window.innerWidth>500?"":'mb-4'}>Metropole Laboratories private Limited was established in 2018 in the Capital City of Islamabad. It is one of the most advanced Pathology Laboratory of the country & is ISO 15189 accredited. </p>

                }
                    <button className='w-20 p-3 border-0 m-0 mb-2 mt-5'
                     onClick={()=>{
                      // window.open('http://futurescientific.com.pk/')
                      // window.open('https://www.fslabs.com.pk/')
                      window.open('https://mpl-labs.pk')


              }} 
                  style={{
                  backgroundColor:"#f4b800",
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.brandReadMoreBtnTextSize,
                  alignSelf:"center",
                  cursor:"pointer",
                  marginTop:window.innerWidth>500?"100vh":"0%"
                  }}>Read More</button>
                </div>
              </div>
            </div>
            
           
            {/* 5 */}
            <div className= {window.innerWidth>500?'m-0 px-1 py-4  shadow businessBox':'m-0 px-1 py-4 mt-5 shadow businessBox'} style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
             <div className='row m-0 p-0' >
                <div className='col-12 col-md-12 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='img-fluid m-0 pt-3' style={{paddingTop:window.innerWidth>500?"16%":"0%",}} src={`${b4}`}></img>
                </div>
                </div>
                <div className='col-12 col-md-12 m-0 p-0 aboutText'
                style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}
                >
                    {/* <h5  style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%",marginTop:window.innerWidth>500?"24%":"0%",}} className='mb-2'>General Practice</h5> */}
                    <p  style={{marginLeft:window.innerWidth>500?"4%":"0%",marginRight:window.innerWidth>500?"4%":"0%",marginTop:window.innerWidth>500?"24%":"0%"}} className={window.innerWidth>500?"":'mb-4'}>General Practice Health Care Centre was founded in the Capital City of Islamabad in 2020 is providing high end health care services in the area of General Medicines, Gastroenterology, Pediatrics, Gynecology, Dermatology & Aesthetics, Dentistry, Psychiatry, Neurology, Plastic Surgery, ENT & General surgery.</p>
                    <button className='w-20 p-3 border-0 m-0 mb-2 mt-3'
                     onClick={()=>{
                      // window.open('http://futurescientific.com.pk/')
                      // window.open('https://www.fslabs.com.pk/')
                      // window.open('https://mpl-labs.pk')
                      window.open('https://generalpractice.com.pk/')



              }} 
                  style={{
                  backgroundColor:"#f4b800",
                  borderRadius:Styles.brandReadMoreBtnRadius,
                  color:Styles.ReadMoreBtnTextColor.themeColorDark,
                  fontSize:Styles.brandReadMoreBtnTextSize,
                  alignSelf:"center",
                  cursor:"pointer"
                  }}>Read More</button>
                </div>
              </div>
            </div>
            {/* 6 */}
            {/* <div className='m-0 px-1 py-4 shadow businessBox' style={{backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0'>
                <div className='col-12 col-md-4 m-0 p-0'>
                <div className='p-3 d-flex justify-content-center'>
                <img className='workLogoSpin2 img-fluid m-0 pt-0' src={`${b6}`}></img>
                </div>
                </div>
                <div className='col-12 col-md-8 m-0 p-0 aboutText'>
                    <h5 className='mb-2'>Ecommerce</h5>
                    <small>Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                </div>
              </div>
            </div> */}
            {/* 7 */}
          

             </div>
         </div>
          
         </div>
      </div>
    </div>
    </div>
  )
}

export default About