import React from 'react'
import Styles from '../../../Assets/Styles/Style'
import T1 from '../../../Assets/Images/Tm1.jpg'
import T2 from '../../../Assets/Images/Tm2.jpg'
import T3 from '../../../Assets/Images/Tm3.jpg'
import T4 from '../../../Assets/Images/Tm4.jpg'
import S3 from '../../../Assets/Images/S1.png'
import TL from '../../../Assets/Images/TeamLogo.png'
import b1 from '../../../Assets/Images/WebDesign.png'
import IshtiyaqAhmed from "../../../Assets/Images/Ceo/IshtiyaqAhmed.jpg"
import Najamfarooq from "../../../Assets/Images/Ceo/NajamFarooq.jpg"


import ZahidP from '../../../Assets/Images/Zahid.png'
import Amir from '../../../Assets/Images/amir.png';
import Saleem from '../../../Assets/Images/saleem1.png';
import Ibraheem from '../../../Assets/Images/ibraheem1.png';
import Usama from '../../../Assets/Images/usama.jpg';
const TeamNajamFarooq=()=> {
  return (
    <div className='container-fluid  m-0 px-5  p-0 d-flex justify-content-center teampaddingremove' style={{backgroundColor:Styles.siteStrategiesBackgroundColor}}>
       
     <div className='row d-flex flex-column flex-md-row  mx-0 my-3  m-0 p-0 justify-content-around ' style={{width:'85%'}}>
     {/* <div className='col-12 m-0 p-0 py-5 d-flex justify-content-center'  
      // style={{backgroundImage:`url(${Team})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'85vh'}}
      >   
        <h1 style={{fontSize:Styles.strategyHeading,}} className='fw-bolder' >What We Are</h1>
      
      </div> */}
        <div className='col-md-4 col-sm-12   m-0 p-0 d-flex flex-column align-items-start marginremove' style={{borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,backgroundColor:Styles.ReadMoreBtnTextColor.themeCommonColor}}>
           {/* <div className=' mt-5  w-100  d-flex '>
<div className=' mx-4 gap-2  d-grid gap-3  ' >
<img src={TL} />
<h1>Our Team</h1>
<p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
<button style={{ borderRadius: Styles.brandReadMoreBtnRadius , color:Styles.ReadMoreBtnTextColor.themeColorDark , backgroundColor:'transparent'  }} className='  mb-2 py-4 w-75 fw-bold fs-5 ' > LEARN MORE </button>
<p> Images from  </p>
</div>
           </div> */}
           <div className='  ' >
            
           <div  className="col-12  col-md-12 mobileteam ">
                <div className="p-3 " style={{ }}>
                <div className='m-0 p-0 d-flex flex-column' style={{}}>
                  <img  className='img-fluid m-0 p-0' src={`${Najamfarooq}`} style={{borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,height:'60vh',width:"52vh"}}></img>
                   <p className='fs-6  fw-bold px-2 mt-3 m-0'>
                     Co-Founder
                   </p>
                   <h3 className='my-2 px-2 fs-4 ' >
                    Dr. NAJAM FAROOQ, Ph.D
                   </h3>
                   {/* <p className='   m-0 px-2'>
                   Sample text. Click to select the text box. Click again or double click to start editing the text.
                   
                   </p> */}
                  
                </div>
                </div>
              </div>

           </div>
           
        </div>
        {/* Member main box */}
        <div  className='col-md-4 col-sm-12   m-0 p-0 d-flex flex-column align-items-start marginremove' style={{borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,backgroundColor:Styles.ReadMoreBtnTextColor.themeCommonColor}}>
           {/* <div className=' mt-5  w-100  d-flex '>
<div className=' mx-4 gap-2  d-grid gap-3  ' >
<img src={TL} />
<h1>Our Team</h1>
<p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
<button style={{ borderRadius: Styles.brandReadMoreBtnRadius , color:Styles.ReadMoreBtnTextColor.themeColorDark , backgroundColor:'transparent'  }} className='  mb-2 py-4 w-75 fw-bold fs-5 ' > LEARN MORE </button>
<p> Images from  </p>
</div>
           </div> */}
           <div className='  ' >
            
           <div  className="col-12  col-md-12 mobileteam ">
                <div className="p-3 " style={{ }}>
                <div className='m-0 p-0 d-flex flex-column'>
                  <img  className='img-fluid m-0 p-0' src={`${IshtiyaqAhmed}`} style={{borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,height:'60vh',width:"52vh"}}></img>
                   <p className='fs-6  fw-bold px-2 mt-3 m-0'>
                     Co-Founder
                   </p>
                   <h3 className='my-2 px-2 fs-4 ' >
                   Dr. ISHTIAQ AHMED, Ph.D
                   </h3>
                   {/* <p className='   m-0 px-2'>
                   Sample text. Click to select the text box. Click again or double click to start editing the text.
                   
                   </p> */}
                  
                </div>
                </div>
              </div>

           </div>
           
        </div>
        {/* <div data-aos={"fade-down"} className=' col-12 col-md-6  m-0 p-0' style={{borderradius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius}}> */}
            {/* <div className='container-fluid teampaddingremove ' style={{padding:'0 0 0 2.5rem'}}> */}
            
             {/* Member 1 */}
              {/* 1 */}
              {/* <div className='m-0 px-1 py-4 shadow businessBox' style={{opacity:'.8',backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight,width:'32%',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,minHeight:'35vh'}}>
              <div className='row m-0 p-0' style={{cursor:"pointer"}} onClick={()=>{
                      window.open('https://mpl-labs.pk')
                      

              }}> */}
             
                {/* <div className="p-3 border" style={{minHeight:'100vh',borderRadius:Styles.ReadMoreBtnTextColor.themeBoxeBorderRadius,backgroundColor:Styles.ReadMoreBtnTextColor.themeColorLight}}>
                
                <div className='col-12 col-md-12 m-0 p-0 aboutText'>
                    <h5 className='mb-2'>CEO </h5>
                    <br/>
                    <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                <br/>
                <br/>

                    <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                    <br/>
                <br/>
                <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                <br/>
                <br/>

                    <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                    <br/>
                <br/>

                    <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                    <br/>
                <br/>

                    <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                    <br/>
                <br/>
                <small >Sample text. Click to select the text box. Click again or double click to start editing the text.</small>
                    <br/>
                <br/>
                </div>
                
            
            </div> */}
           







             
            {/* </div> */}
        {/* </div> */}
     </div>
    </div>
  )
}

export default TeamNajamFarooq